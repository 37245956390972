import React from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import {Link} from 'gatsby';
import { BiLoaderAlt } from 'react-icons/bi'; 

//images
import placeholder_img from '../images/placeholder.svg';


class Nyhet extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            data: [],
            is_loaded: false
        };
    }

    componentDidMount(){
        const news_id = this.props.location.hash.substring(1);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: news_id })
        };

        fetch('https://crediflow.com/admin/api/news_by_id.php', requestOptions)
        .then(res => res.json())
        .then( json => {
            this.setState({
                is_loaded: true, 
                data: json,       
            })
        })
    }

    render(){
        const { is_loaded, data} = this.state;
        return(
            <Layout>
                <Helmet>
                    <title>News | Crediflow</title>
                    <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                    <meta name="description" content="Senaste nyheterna från Crediflow" />
                    <meta name="keywords" content="Nyheter" />
                    <meta property="og:title" content="Crediflow AB" />
                    <meta property="og:tyope" content="website" />
                    <meta property="og:discription" content="Senaste nyheterna från Crediflow" />
                    <meta property="og:image" content=" " />
                    <meta property="og:locale" content="sv_SE" />
                    <meta property="og:url" content="https://www.crediflow.com/news" />
                    <link rel="cannonical" href="https://www.crediflow.com/news" />
                </Helmet>


                {!is_loaded ? 
                    <section className="min-h-screen bg-gray-section-background py-16">
                        <div className="container mx-auto px-4 xl:px-24">
                            <div className="w-full flex justify-center items-center py-10">
                                <BiLoaderAlt className="animate-spin w-16 h-16 text-blue-excendo" />
                            </div>
                        </div>
                    </section> 
                :
                <>
                    <section className="bg-gray-100 pb-24">
                        <div className="container mx-auto px-4 xl:px-24">
                            <div className="w-full lg:w-5/6 p-6 mx-auto ">
                                {data.map(i => 
                                    <div className="w-full flex flex-col">
                                        <div className="w-full " >
                                            <img
                                                className="h-full w-full object-cover object-center block rounded-t-md" 
                                                alt="bild"
                                                src={i.image_size === "0" ? placeholder_img : i.image_url}  />
                                        </div>
                                        <div className="w-full h-full flex flex-col justify-between overflow-hidden">                   
                                            <p className="text-gray-500">{i.created_at.substring(0,10)}</p>
                                            <h1 className="mt-10 font-bold text-gray-800 text-2xl lg:text-3xl">{i.title}</h1>
                                            <div className="mt-10 text-gray-700" dangerouslySetInnerHTML={{ __html: i.body}} />
                                        </div>
                                        <div className="mt-10 w-full">
                                            <Link to="/news" className="px-6 py-2 rounded-full text-white bg-blue-custome shadow-md hover:opacity-90">More news</Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                </>
                }
            </Layout>
        );
       
    }   
}
export default Nyhet;